import React from "react";


function Content_Tarjetas() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner5.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
        <h2>Tarjetas</h2>
        <h4 class="text-subtitle">Amibus ofrece una variedad de medios de pago para adaptarse a las necesidades de todos los usuarios. Entre las opciones se encuentran:</h4>
        
              
        <div className="container3">
      <div className="content3">
      <div className="group">

        <img src="./img/Tar1.png" alt="Imagen" className="image4" />
        <div className="content-details">
        <h2>Tarjeta Adulto:</h2>
        <p className="text3">Una tarjeta diseñada para el público en general, brindando una solución eficiente y fácil de usar para el pago del transporte público.</p>
      </div>
      </div>
      </div>
    </div>

    <div className="container3">
      <div className="content3">
      <div className="group">

        <img src="./img/Tar2.png" alt="Imagen" className="image4" />
        <div className="content-details">
        <h2>Tarjeta Adulto Mayor:</h2>
        <p className="text3">Especialmente creada para adultos mayores, esta tarjeta proporciona beneficios y tarifas preferenciales, para los adultos mayores de 65 años, 
        garantizando un viaje cómodo y accesible. Recuerda que esta tarjeta es personal e intransferible.</p>
      </div>
      </div>
      </div>
    </div>

    <div className="container3">
      <div className="content3">
      <div className="group">

        <img src="./img/Tar3.png" alt="Imagen" className="image4" />
        <div className="content-details">
        <h2>Tarjeta Nacional de Estudiante TNE:</h2>
        <p className="text3">Dirigida a estudiantes, la Tarjeta Nacional de Estudiante (TNE) ofrece una solución conveniente y económica para el pago del transporte público, 
        adaptada a las necesidades educativas de los usuarios. Recuerda que esta tarjeta es personal e intransferible</p>
      </div>
      </div>
      </div>
    </div>




                </div>
            </section>
        </div>
    );
}

export default Content_Tarjetas;