import React from "react";

function Content_BusApp() {
  return (
    <div>
      <div className="hero-image">

        <img src="./img//Banner15.png" alt="Hero" className="hero-image" />

      </div>

      <section class="features-icons bg-light text-center det-ails">
        <div className="interest-links">
          <h2 >BusApp</h2>

          <div className="container3">
            <div className="content5  ">
              <div className="group">

                <img src="./img/busapp.png" alt="Imagen" className="image4" />
                <div className="content-detailsBA">
                  <h2>Descarga gratis
                    la app de BusApp</h2>
                  <p className="text3">Facilita tu día a día con BusApp: En la cual podrás administrar tu saldo y tarjetas, planificar tus viajes, pagar con QR y recargar tus
                    tarjetas, además de disfrutar de una variedad más de beneficios. Nuestra aplicación está diseñada para hacer tu vida más fácil y cómoda.</p>

                  <div className="images-container">
                    <a href="https://apps.apple.com/co/app/busappv5/id6468486390" target="_blank">
                      <img src="./img/appstore.png" alt="Primera Imagen" className="additional-image" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.busmatick.busapp_v2&hl=es_CO&gl=US&pli=1" target="_blank">
                      <img src="./img/googleplay.png" alt="Segunda Imagen" className="additional-image" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
}

export default Content_BusApp;