import React from "react"
import { Link } from "react-router-dom";
import SignInButton from  "../components/CustomButton/SinInButton/SignInButton";
import RegisterButton  from  "../components/CustomButton/RegisterButton/RegisterButton";

function Nav() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light static-top header-a">
            <div className="container nav-container">
             <div className="logo-container">
             <img src="./img//Amibus_Logo.png" alt="Logo" className="logo" />
             </div>
             <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse alink" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                           
                            <Link to="/" className="nav-link">Inicio</Link>
                        </li>

                        <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                ¿Qué es amibus?
        </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                
                                <Link to="/ComoUsarla" className="dropdown-item">¿Cómo usarla?</Link>
                                <Link to="/CuentaAmibus" className="dropdown-item">Cuenta amibus</Link>
                            </div>
                        </li>
                        
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Medios de pago
        </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/Tarjetas" className="dropdown-item">Tarjetas</Link>
                            <Link to="/PagoConQR" className="dropdown-item">Pago con QR</Link>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               Tarifas
        </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/TarifasDelServicio" className="dropdown-item">Tarifas del servicio</Link>
                            <Link to="/TarifasDeLasTarjetas" className="dropdown-item">Tarifas de las tarjetas</Link>



                            <Link to="/CargaOnline" className="dropdown-item">Carga online</Link>
                            </div>
                        </li>


                        <li className="nav-item">
                        <Link to="/ViajeDeEmergencia" className="nav-link">Viaje de emergencia</Link>
                        </li>



                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               Puntos de recarga
        </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/PuntosDeRecarga" className="dropdown-item">Listado de puntos de recargas</Link>
                                <Link to="/CargaNoRealizada" className="dropdown-item">¿Qué hago si mi carga no se realizó?</Link>

                                
                                <Link to="/RedDeRecargas" className="dropdown-item">Red de recargas</Link>
                            </div>
                        </li>


                        <li className="nav-item">
                        <Link to="/Mapa" className="nav-link">Mapa</Link>
                        </li>



                        <li className="nav-item">
                        <Link to="/BusApp" className="nav-link">BusApp</Link>
                        </li>
                        
                        <RegisterButton/>
                        
                        <SignInButton />
                            
         
                      
                        
                       




                    </ul>


                </div>


            </div>
        </nav>
    );
}

export default Nav;


                        // <div className="button-container">
                        //     <a className="start custom-button" href="https://gea2.busmatick.com">
                        //     <i class="fa fa-store"></i>
                        //     {/* <img className="img-button" src="./img//seguridad.png" alt="Icono de inicio de sesión" /> */}
                        //      <span className="button-text">Iniciar sesión</span>
                        //     </a>
                        // </div>



                        // {/* <li className="nav-item">
                        // <Link to="/Registrar" className="nav-link">Registrate</Link>
                        // </li> */}
