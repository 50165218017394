import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getContactInfo,
  defaultContactInfo,
} from "../../Services/ContactInfoServices";


const Footer = () => {

  const [contactInfo, setContactInfo] = useState(defaultContactInfo);

  useEffect(() => {
    const fetchContactInfo = async () => {
      const data = await getContactInfo();
      setContactInfo(data);
    };
    fetchContactInfo();
  }, []);

  return (
    <><div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">

          <img src="./img//Amibus_Logo.png" alt="Logo" className="footer-logo-container" />

        </div>

        <div className="container2">
          <div className="circle2">
            <img src="./img//email.png" alt="Imagen" />
          </div>
          <div className="text-container2">
            <p>Correo Electrónico</p>
            <a href={`mailto:${contactInfo.correo_pqr}`}>
              {contactInfo.correo_pqr}
            </a>
          </div>
        </div>
        <div className="container2">
          <div className="circle2">
            <img src="./img//phone.png" alt="Imagen" />
          </div>
          <div className="text-container2">
            <p>Teléfono</p>
            <p>{contactInfo.telefono}</p>
          </div>
        </div>

      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <Link to="/QueEsAmibus"><h5><span>¿Qué es amibus?</span></h5></Link>
          <Link to="/ComoUsarla"><span>¿Cómo usarla?</span></Link>
          <Link to="/CuentaAmibus"> <span>Cuenta amibus</span></Link>
        </div>
        <div className="footer-section-columns">
          <h5> <span>Medios de pago</span></h5>
          <Link to="/Tarjetas"><span>Tarjetas</span></Link>
          <Link to="/PagoConQR"> <span>Paga con QR</span></Link>
        </div>
        <div className="footer-section-columns">
          <h5>  <span>Tarifas</span></h5>
          <Link to="/TarifasDelServicio"><span>Tarifas del servicio</span></Link>
          <Link to="/TarifasDeLasTarjetas"> <span>Tarifas de las tarjetas</span></Link>
          <Link to="/CargaOnline"> <span>Carga online</span></Link>
        </div>

        <div className="footer-section-columns">
          <h5><span className="text-footer">Puntos de recarga</span></h5>
          <Link to="#"><span>Listado de puntos de recargas</span></Link>
          <Link to="/CargaNoRealizada"> <span>¿Qué hago si mi carga no se realizó?</span></Link>
          <Link to="/RedDeRecargas"><span>Red de recargas</span></Link>
        </div>

      </div>

    </div><hr className="footer-line" /><p className="copyright">Todos los derechos reservados - © 2024 amibus</p></>

  );
};

export default Footer;
