import React from "react";

function Content_ComoUsarla() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner3.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2>¿Cómo usar la tarjeta amibus?</h2>
            <h4 class="text-subtitle">Para utilizar Amibus de manera eficiente, sigue estos simples pasos:</h4>

                <div class="container">
                    <div class="row2">

                        <div class="col-lg-4">
                        <div className="cardV2">
                          <h2>1.</h2>
                          <div className="text-container">
                          <h3 class="text-card">Obtén una tarjeta adulto o adulto mayor.</h3>
                         </div>
                        </div>
                        </div>


                        <div class="col-lg-4">
                        <div className="cardV2">
                          <h2>2.</h2>
                          <div className="text-container">
                          <h3 class="text-card">Regístrate creando una cuenta de usuario a través de la descarga de BusApp en tu teléfono móvil o visitando la página web www.amibus.cl.</h3>
                         </div>
                        </div>
                        </div>


                     
                        <div class="col-lg-4">
                        <div className="cardV2">
                          <h2>3.</h2>
                          <div className="text-container">
                          <h3 class="text-card">Vincula la tarjeta recién adquirida a tu cuenta personal.</h3>
                         </div>
                        </div>
                        </div>

                        <div class="col-lg-4">
                        <div className="cardV2">
                          <h2>4.</h2>
                          <div className="text-container">
                          <h3 class="text-card">Recarga saldo en tu tarjeta desde cualquier punto de recarga en la ciudad. Muy pronto podrás hacerlo a través de BusApp (en tu celular) o en www.amibus.cl.</h3>
                         </div>
                        </div>
                        </div>

                        
                        <div class="col-lg-4">
                        <div className="cardV2">
                          <h2>5.</h2>
                          <div className="text-container">
                          <h3 class="text-card"> ¡Listo! Utiliza tu tarjeta para realizar tus viajes de forma fácil y conveniente.</h3>
                         </div>
                        </div>
                        </div>

        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Content_ComoUsarla;