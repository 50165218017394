import React, { Component} from "react"

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ContentRegistrar from "./ContentRegistrar";

class Registrar extends Component {
    
    render() {
        return (
            <div className="main-container">
                <Header />
                    <ContentRegistrar/>
                <Footer />         
            </div>          
        )
    }
}

export default Registrar;