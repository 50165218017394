import React from "react";
import MapComponent from "./MapComponent"; // Importar el componente del mapa

const MapContainer = () => {
  return (
    <div style={containerStyles}>
      <div style={largeDivStyles}>
        {/* Aquí se muestra el mapa */}
        <MapComponent />
      </div>
      <div style={smallDivStyles}></div>
    </div>
  );
};

// Estilos para el contenedor principal
const containerStyles = {
  width: "100%",
  minHeight: "calc(80vh - 3rem)", // Altura mínima del contenedor, considerando el padding bottom
  backgroundColor: "#ffffff",
  display: "flex",
  justifyContent: "center",
  alignItems: "stretch",
};

// Estilos para el div grande
const largeDivStyles = {
  flex: "0 0 75%", // 75% del ancho de la pantalla
  maxWidth: "75%", // Limitar el ancho al 75% de la pantalla
};

// Estilos para el div pequeño
const smallDivStyles = {
  flex: "0 0 25%", // 25% del ancho de la pantalla
  maxWidth: "25%", // Limitar el ancho al 25% de la pantalla
  backgroundColor: "#DE1F25",
  paddingBottom: "3rem", // Padding bottom de 3 rem
};

export default MapContainer;
