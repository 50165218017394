import React, { useState, useEffect } from "react";
import {
  getContactInfo,
  defaultContactInfo,
} from "../../Services/ContactInfoServices";

function Content_CargaNoRealizada() {
  const [contactInfo, setContactInfo] = useState(defaultContactInfo);

  useEffect(() => {
    const fetchContactInfo = async () => {
      const data = await getContactInfo();
      setContactInfo(data);
    };
    fetchContactInfo();
  }, []);
  return (
    <div>
      <div className="hero-image">
        <img src="./img//Banner12.png" alt="Hero" className="hero-image" />
      </div>
      <section class="features-icons bg-light text-center det-ails">
        <div className="interest-links">
          <h2>¿Qué hago si mi carga no se realizo?</h2>

          <div className="container3">
            <div className="content4">
              <div className="group">
                <img src="./img/img05.png" alt="Imagen" className="image4" />
                <div className="content-details">
                  <h2>Estamos aquí para ayudarte</h2>
                  <p className="text3">
                    Si tu carga no se realizó correctamente, no te preocupes.
                    Contacta nuestro call center al {contactInfo.telefono} o
                    envía una solicitud de soporte al correo{" "}
                    <a href={`mailto:${contactInfo.correo_pqr}`}>
                      {contactInfo.correo_pqr}
                    </a>
                    . Estamos comprometidos a resolver cualquier inconveniente
                    que puedas tener y garantizar una experiencia sin
                    complicaciones con amibus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content_CargaNoRealizada;
