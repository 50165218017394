import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import iconPR from "../Map/Tarjeta3.png"; // Importar la imagen del marcador

// const YOUR_API_KEY = "AIzaSyAs4ymdyAx9YOikWZSLEs0qSphnX3q4enM";
const YOUR_API_KEY = "AIzaSyAugU93B3jpPvNHTqIb4YlGwrLsdHT2R1Q"; //Llave 02 Jul 2024

// const YOUR_API_KEY = "AIzaSyB_2eTPjgWn0q8a1FQ8tYXGYUCqSItCqI8";

export class MapComponent extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: null,
    markers: [],
    // markers: [
    //   {
    //     name: "Los poetas 10900",
    //     position: { lat: -23.560833, lng: -70.383333 }
    //   },
    //   {
    //     name: "Huamachuco 10406",
    //     position: { lat: -23.566667, lng: -70.382778 }
    //   },
    //   {
    //     name: "Huamachuco 10170",
    //     position: { lat: -23.570000, lng: -70.382500 }
    //   },
    //   {
    //     name: "Av Bonilla 9737",
    //     position: { lat: -23.573611, lng: -70.380000 }
    //   },
    //   {
    //     name: "Av Bonilla 9570",
    //     position: { lat: -23.575833, lng: -70.379722 }
    //   },
    //   {
    //     name: "Capitan Carlos Condell 2337",
    //     position: { lat: -23.650278, lng: -70.398889 }
    //   },
    //   {
    //     name: "Manuel Antonio Matta 1919 (Kiosko Azul)",
    //     position: { lat: -23.654167, lng: -70.401667 }
    //   },
    //   {
    //     name: "Jose Santos Ossa 1950",
    //     position: { lat: -23.655000, lng: -70.400278 }
    //   },
    //   {
    //     name: "Jose Miguel Carrera 1639",
    //     position: { lat: -23.659444, lng: -70.401944 }
    //   },
    //   {
    //     name: "Av Angamos 1153",
    //     position: { lat: -23.664722, lng: -70.402778 }
    //   },
    //   {
    //     name: "Av Bonilla 9570",
    //     position: { lat: -23.665278, lng: -70.403611 }
    //   },
    //   {
    //     name: "Av Angamos 0475",
    //     position: { lat: -23.677222, lng: -70.409167 }
    //   }
    // ],
    zoomLevel: 11.7
  };

  async componentDidMount(){
    try {
      const response = await fetch('/data/markersmap.json');
      if (response.ok) {
        const data = await response.json();
        this.setState({ markers: data });
      } else {
        // console.error('Failed to fetch markers:', response.status);
      }
    } catch (error) {
      // console.error('Error fetching markers:', error);
    }
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  handleMarkerSelect = (event) => {
    const selectedMarker = event.target.value;
    const { markers } = this.state;
    const selectedPlace = markers.find(marker => marker.name === selectedMarker);
    
    if (selectedPlace) {
      const zoomLevel = 14;
      this.setState({ selectedPlace, zoomLevel });
    }
  };

  render() {
    const { selectedPlace, markers, zoomLevel } = this.state;

    return (
      <div style={mapContainerStyles}>
        <div style={menuContainerStyles}>
          <select value={selectedPlace ? selectedPlace.name : ""} onChange={this.handleMarkerSelect}>
            <option value="">Puntos de recargas</option>
            {markers.map((marker, index) => (
              <option key={index} value={marker.name}>
                {marker.name}
              </option>
            ))}
          </select>
        </div>
        <Map
          google={this.props.google}
          zoom={zoomLevel || 11.7}
          style={mapStyles}
          initialCenter={{ lat: -23.613278, lng: -70.398889 }}
          centerAroundCurrentLocation={true}
          center={selectedPlace ? selectedPlace.position : undefined}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              onClick={this.onMarkerClick}
              name={marker.name}
              position={marker.position}
              icon={{
                url: iconPR,
                scaledSize: new this.props.google.maps.Size(40, 40) // Tamaño personalizado del icono del marcador
              }}
            />
          ))}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onClose}
          >
            <div>
              <h5 className="marker-text">{selectedPlace ? selectedPlace.name : ""}</h5>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

const mapContainerStyles = {
  width: "100%",
  height: "60vh",
  position: "relative",
  paddingBottom: "3rem",
  paddingTop: "3rem",
  paddingLeft: "5rem",
};

const menuContainerStyles = {
  position: "absolute",
  left: "5rem",
  top:  "0.5rem",
  zIndex: 1,
};

const mapStyles = {
  width: "100%",
  height: "100%",
};

export default GoogleApiWrapper({
  apiKey: YOUR_API_KEY
})(MapComponent);
