import React from 'react';
import '../SinInButton/SignInButton.css';

const SignInButton = () => {
  return (
    <div className="button-container">
      <a className="btn sign-in-button" href="https://gea2.busmatick.com">
        Iniciar sesión Comercios
      </a>
    </div>
  );
};


export default SignInButton;