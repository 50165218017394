import React from "react";


function Content_CuentaAmibus() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner4.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2 >Cuenta Amibus</h2>
            <div className="container3">
            <div className="content3">
            <img src="./img/img02.png" alt="Imagen" className="image3" />
  
            <p className="text3">La cuenta amibus se presenta como una cuenta virtual vinculada a tu RUT, ofreciendo una forma moderna y eficaz de gestionar tus pagos 
            de transporte público. A través de esta cuenta, tienes la facilidad de pagar tus viajes utilizando la tarjeta amibus o generando un código QR directamente 
            desde la aplicación BusApp en tu celular. Esta solución innovadora te brinda comodidad y rapidez, simplificando el proceso de pago en el transporte público.</p>
            </div>
            </div>

                </div>
            </section>
        </div>
    );
}

export default Content_CuentaAmibus;