import React from "react";


function Content_TarifasDelServicio() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner7.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
        <h2>Tarifas del servicio</h2>
               
              
        <div className="container3">
      <div className="content4">
      <div className="group">

        <img src="./img/img01.png" alt="Imagen" className="image4" />
        <div className="content-details">
        <h2>Tarifa Normal - $600</h2>
        <p className="text4">Viaja cómodamente con la Tarifa Normal de Amibus a solo $600. Válida los siete días de la semana y durante todo el horario de servicio, esta tarifa constante garantiza un 
        transporte público accesible en cualquier momento que lo necesites.</p>

        <h2>Tarifa Estudiantes - $200</h2>
        <p className="text4">Para los estudiantes de Enseñanza Media y Educación Superior, Amibus ofrece una tarifa especial de $200. Válida durante todos los días de la semana y en cualquier momento del horario de servicio, 
        esta tarifa proporciona los mismos beneficios que la tarifa normal. Paga con tu TNE, facilitando tu movilidad estudiantil.</p>

        <h2>Tarifa Adulto Mayor - $300</h2>
        <p className="text4">Los adultos mayores pueden disfrutar de la comodidad de viajar con Amibus a una tarifa preferencial de $300. Válida durante todo el horario de servicio, esta tarifa especial se aplica mediante la 
        Tarjeta Adulto Mayor, disponible en los Centros de Atención al Usuario. Requisitos: 65 años cumplidos o más para hombres y 60 años cumplidos o más para mujeres. Viaja con Amibus de manera asequible y adaptada a tus necesidades.</p>
      </div>
      
      </div>
      </div>
    </div>
                </div>
            </section>
        </div>
    );
}

export default Content_TarifasDelServicio;