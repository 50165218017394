import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomDialog from "../../components/components/CustomDialog/CustomDialog";
import {
  getConfig,
} from "../../Services/ConfigServices";

function Content_Inicio() {
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    const fetchConfig = async () => {
      await getConfig().then(config => {
        const expirateDataConfig = config.expirationDate;
        const currentDate = new Date();
        const expireDate = new Date(expirateDataConfig);
        if ( currentDate < expireDate ) {
            setShowModal(true);          
        } 
      });
    }
  
    fetchConfig();
  }, []);

  const handleCloseDialog = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="hero-image">
        <Link to="/QueEsAmibus">
          <img src="./img/Banner.png" alt="Hero" className="hero-image" />
        </Link>
      </div>

      <section class="features-icons bg-light text-center det-ails">
        <div className="interest-links">
          <h2>Enlaces de Interés</h2>

          <div class="container">
            <div class="row">
              <div class="card-container">
                <div className="card">
                  <Link to="/QueEsAmibus">
                    <div className="image-container">
                      <img src="./img//Bus.png" alt="Bus 2" />
                    </div>
                  </Link>
                  <div className="text-container">
                    <h3>¿Qué es Amibus?</h3>
                  </div>
                </div>
              </div>

              <div class="card-container">
                <div className="card">
                  <Link to="/Tarjetas">
                    <div className="image-container">
                      <img src="./img//cards.png" alt="Bus 2" />
                    </div>
                  </Link>
                  <div className="text-container">
                    <h3>Tarjetas</h3>
                  </div>
                </div>
              </div>

              <div class="card-container">
                <div className="card">
                  <Link to="/ViajeDeEmergencia">
                    <div className="image-containerV2">
                      <img
                        className="img-container"
                        src="./img//Viaje_Emergencia.png"
                        alt="Bus 2"
                      />
                    </div>
                  </Link>
                  <div className="text-container">
                    <h3>Viaje de emergencia</h3>
                  </div>
                </div>
              </div>

              <div class="card-container">
                <div className="card">
                  <Link to="/Mapa">
                    <div className="image-container">
                      <img src="./img//map.png" alt="Bus 2" />
                    </div>
                  </Link>
                  <div className="text-container">
                    <h3>Mapa</h3>
                  </div>
                </div>
              </div>

              <div class="card-container">
                <div className="card">
                  <Link to="/BusApp">
                    <div className="image-containerV2">
                      <img
                        className="img-container"
                        src="./img//BusAppIcon.png"
                        alt="Bus 2"
                      />
                    </div>
                  </Link>
                  <div className="text-container">
                    <h3>BusApp</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CustomDialog show={showModal} handleClose={handleCloseDialog}/>
    </div>
  );
}

export default Content_Inicio;
