import React, { Component } from "react"

import Header from '../../components/Header/Header';
import Content_TarifasDelServicio from "./Content_TarifasDelServicio";
import Footer from '../../components/Footer/Footer';

class Tarjetas extends Component {
    render() {
        return (

            <div class="main-container">
                <Header />
                <Content_TarifasDelServicio />
                <Footer />
            </div>

        )
    }
}

export default Tarjetas;