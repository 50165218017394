import React from "react";


function Content_QueEsAmibus() {
    return (
        <div>
      <div className="hero-image">

      <img src="./img//Banner2.png" alt="Hero" className="hero-image" />

      </div>

            <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
            <h2>Amibus</h2>


        <div className="container3">
        <div className="content3">
        <img src="./img/img01.png" alt="Imagen" className="image3" />
        <p className="text3">Amibus se presenta como una revolucionaria modalidad de pago para el transporte en la línea de buses eléctricos E01 en Antofagasta. 
        Esta plataforma representa una nueva y mejorada manera de abonar los servicios de movilidad para esta línea de transporte, brindando a los usuarios una 
        experiencia moderna y eficaz al realizar sus pagos. Con Amibus, el proceso de pago se simplifica, contribuyendo así a la modernización del sistema de 
        transporte público en la ciudad.</p>
        </div>
        </div>

                </div>
            </section>
        </div>
    );
}

export default Content_QueEsAmibus;