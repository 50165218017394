import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import './App.css';

import Home from './views/Index/Home';
import QueEsAmibus from './views/Index/QueEsAmibus';
import ComoUsarla from './views/Index/ComoUsarla';
import CuentaAmibus from './views/Index/CuentaAmibus';
import Tarjetas from './views/Index/Tarjetas';
import PagoConQR from './views/Index/PagoConQR';
import TarifasDelServicio from './views/Index/TarifasDelServicio';
import TarifasDeLasTarjetas from './views/Index/TarifasDeLasTarjetas';
import CargaOnline from './views/Index/CargaOnline';
import ViajeDeEmergencia from './views/Index/ViajeDeEmergencia';
import CargaNoRealizada from './views/Index/CargaNoRealizada';
import RedDeRecargas from './views/Index/RedDeRecargas';
import Mapa from './views/Index/Mapa';
import BusApp from './views/Index/BusApp';
import PuntosDeRecarga from './views/Index/PuntosDeRecarga';
import Registrar from "./views/Index/Registrar";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/QueEsAmibus" element={<QueEsAmibus />} />
        <Route path="/ComoUsarla" element={<ComoUsarla />} />
        <Route path="/CuentaAmibus" element={<CuentaAmibus />} />
        <Route path="/Tarjetas" element={<Tarjetas />} />
        <Route path="/PagoConQR" element={<PagoConQR />} />
        <Route path="/TarifasDelServicio" element={<TarifasDelServicio />} />
        <Route path="/TarifasDeLasTarjetas" element={<TarifasDeLasTarjetas />} />
        <Route path="/CargaOnline" element={<CargaOnline />} />
        <Route path="/ViajeDeEmergencia" element={<ViajeDeEmergencia />} />
        <Route path="/CargaNoRealizada" element={<CargaNoRealizada />} />
        <Route path="/RedDeRecargas" element={<RedDeRecargas />} />
        <Route path="/Mapa" element={<Mapa />} />
        <Route path="/BusApp" element={<BusApp />} />
        <Route path="/PuntosDeRecarga" element={<PuntosDeRecarga />} />
        <Route path="/Registrar" element={<Registrar />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;