import React from 'react';

import MapContainer from '../../components/Map/MapContainer';



function Content_PuntosDeRecarga() {
  return (

    <div>
    <div className="hero-image">

    <img src="./img//Banner11.png" alt="Hero" className="hero-image" />

    </div>



    
    <section class="features-icons bg-light text-center det-ails">
            <div className="interest-links">
        <h2>Listado de puntos de recargas</h2>


        <div className="container2">
        <MapContainer/>      
       </div>

                </div>
            </section>
    </div>

  );
}

export default Content_PuntosDeRecarga;