import React, { useState, useEffect } from "react";
import {
  getContactInfo,
  defaultContactInfo,
} from "../../Services/ContactInfoServices";

function Content_RedDeRecargas() {
  const [contactInfo, setContactInfo] = useState(defaultContactInfo);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const data = await getContactInfo();
        setContactInfo(data);
      } catch (error) {
        console.error("Error:", error);
      }
      
    };
    fetchContactInfo();
  }, []);

  return (
    <div>
      <div className="hero-image">
        <img src="./img//Banner13.png" alt="Hero" className="hero-image" />
      </div>

      <section class="features-icons bg-light text-center det-ails">
        <div className="interest-links">
          <h2>Red de Recargas</h2>

          <div className="container3">
            <div className="content5">
              <div className="group">
                <img src="./img/img01.png" alt="Imagen" className="image4" />
                <div className="content-details">
                  <h2>Únete y Maximiza tus Oportunidades</h2>
                  <p className="text4">
                    Para unirte, simplemente comunícate con nuestros ejecutivos
                    llamando al {contactInfo.telefono} o envía tu solicitud
                    directamente a nuestro correo electrónico{" "}
                    <a href={`mailto:${contactInfo.correo_pqr}`}>
                      {contactInfo.correo_pqr}
                    </a>
                    . Estaremos encantados de guiarte en el proceso y responder
                    a cualquier pregunta que puedas tener.
                  </p>

                  <h2>Soporte Personalizado</h2>
                  <p className="text4">
                    En amibus, nos esforzamos por ofrecerte el mejor servicio
                    posible. Si necesitas soporte o asistencia, no dudes en
                    comunicarte con nuestros ejecutivos llamando al{" "}
                    {contactInfo.telefono}. Estamos aquí para resolver cualquier
                    consulta y asegurarnos de que tengas una experiencia sin
                    contratiempos con Amibus.
                  </p>

                  <h2>¿Cómo Cargar Saldo para Vender?</h2>
                  <p className="text4">
                    Si estás interesado en cargar saldo para vender, el proceso
                    es sencillo:
                  </p>
                  <p className="text4">
                    1. Realiza un depósito en la cuenta corriente{" "}
                    {contactInfo.cuenta_banco} del Banco{" "}
                    {contactInfo.nombre_banco} con el monto que deseas cargar.
                  </p>
                  <p className="text4">
                    2. Envía el comprobante de tu consignación, referenciado con
                    tu RUT, al correo{" "}
                    <a href={`mailto:${contactInfo.correo_rdr}`}>
                      {contactInfo.correo_rdr}
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content_RedDeRecargas;
